.el-tab-pane {
    $block-name: &;
    &__content {
        font-size: 15px;
        line-height: 1.5;
        color: $dark;
        p {
            margin: 0 0 10px;
        }
        ul {
            margin: 0 0 10px;
            padding: 0;
            list-style: unset;
            &:empty {
                margin: 0;
            }
            li {
                font-size: 15px;
                line-height: 1.5;
                color: $dark;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
    }
    &--pmo-categories {
        .categories-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__product-data {
                width: 70%;
            }
            &__pmo-names {
                padding: 20px;
                width: 30%;
            }
            &__title {
                margin: 0 0 10px;
                font-size: 16px;
                font-weight: 600;
            }
            &__list {
                margin: 0 0 10px;
                padding: 0;
                list-style: none;
                &:empty {
                    margin: 0;
                }
                &-item {
                    margin: 0 0 5px;
                    p {
                        margin: 0;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
