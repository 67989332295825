.el-table {
    $block-name: &;
    border-left: 1px solid darken($white-second, 1%);
    border-right: 1px solid darken($white-second, 1%);
    thead {
        th.el-table__cell {
            background-color: darken($white-second, 1%);
        }
    }
    &__cell {
        &--capitalize {
            text-transform: capitalize;
        }
    }
    &--notifications {
        #{$block-name} {
            &__cell {
                &.el-table__expanded-cell {
                    padding: 10px;
                    .el-col {
                        &:not(:last-child) {
                            border-right: 1px solid darken($white-second, 4%);
                        }
                    }
                }
            }
        }
    }

}
