.container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 15px;
    @media all and (min-width: $m) {
        padding: 0 30px;
    }
}
