.el-pagination {
    $block-name: &;
    &--align-right {
        justify-content: flex-end;
        #{$block-name} {
            &__rightwrapper {
                flex-grow: 0;
            }
        }
    }
}
