.el-link {
    $block-name: &;
    &--wrap {
        display: inline-block;
        font-weight: 500;
        white-space: normal;
        word-break: normal;
        color: $indigo;
        cursor: pointer;
        transition: 0.2s color ease-in-out;
        &:hover {
            color: lighten($indigo, 15%);
        }
        span {
            color: $gray;
        }
    }
}
