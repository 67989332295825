.el-select {
    $block-name: &;
    &--capitalize {
        .el-input__inner {
            text-transform: capitalize;
            &:placeholder-shown {
                text-transform: unset !important;
            }
        }
    }
}
