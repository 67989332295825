.el-dialog {
    $block-name: &;
    min-width: 90%;
    @media all and (min-width: $s) {
        min-width: 75%;
    }
    @media all and (min-width: $m) {
        min-width: 800px;
    }
    &__header {
        padding-right: 35px;
    }
    &__title {
        font-size: 20px;
        font-weight: 500;
        word-break: break-word;
    }
    &__headerbtn {
        right: 10px;
        width: 40px;
    }
    &__close {
        width: 26px;
        height: 26px;
        line-height: 26px;
        font-size: 26px;
        svg {
            width: 26px;
            height: 26px;
            path {
                fill: $dark;
            }
        }
    }
    &__body {
        word-break: break-word;
    }
}
