.el-breadcrumb {
    $block-name: &;
    font-size: 14px;
    line-height: 2.0;
    &__item {
        &:last-child {
            #{$block-name}__inner{
                cursor: default !important;
                color: $dark-middle;
            }
        }
    }
    &__inner,
    &__separator {
        color: $dark-middle;
    }
    &__inner{
        //text-transform: capitalize;
        white-space: nowrap;
    }
    &__separator {
        margin: 0 3px;
        font-weight: normal;
        cursor: default !important;
    }
}
