#coaching-user-form
{
    .el-tree-node__content
    {
        display: block;
        height: unset;
        justify-content: space-between;

        .users_block
        {
            padding-left: 48px;
            background-color: #f2f2f2;
        }
    }
}

