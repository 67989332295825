@import "variables";

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: $avenir-next;
    box-sizing: border-box;
}

.home-container {
    padding: 20px 0;
}

.page-title,
.section-title {
    font-family: $avenir-next;
    font-weight: 600;
}

.page-title {
    font-size: 22px;
}

.section-title {
    font-size: 20px;
    &--middle {
        font-size: 18px;
        font-weight: 500;
    }
}

.float-right {
    float: right;
}
.left {
    float: left;
}

.w-100 {
    width: 100% !important;
}
.min-100 {
    min-height: 100px;
}
.text-center {
    text-align: center;
}

.page-title,
.page-tree,
.page-buttons,
.section-title {
    margin: 0 0 20px;
    &--no-offset {
        margin: 0;
    }
    &--small-bottom-offset {
        margin: 0 0 10px;
    }
}

.page-breadcrumbs {
    margin: 0 0 5px;
}

.page-section {
    margin-bottom: 20px;
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }
    &__col {
        margin: 5px 0;
        padding: 0 5px;
    }
    &--bordered-form {
        border: 1px solid $light-gray;
        border-radius: 15px;
        padding: 30px 25px 10px;
    }
    &--equal-space {
        padding: 30px 25px;
    }
    &--offset-bottom-middle {
        margin: 0 0 30px;
    }
    &--product-filters-space {
        padding: 30px 25px 12px;
    }
}

.scroll-lock {
    overflow: hidden;
}

.col-auto {
    flex: 0 0 auto;
}

.max-width-600-tablet-desktop {
    @media all and (min-width: $s) {
        max-width: 600px;
    }
}

.image-placeholder {
    width: 110px;
    height: auto;
    overflow: hidden;
    img {
        max-width: 100%;
        max-height: 100%;
        background-color: darken($white-second, 2%);
    }
}

