.el-tag {
    $block-name: &;
    &--info {
        color: $gray;
        #{$block-name} {
            &__close {
                color: $gray;
            }
        }
    }
}
