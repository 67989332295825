.header-controls {
    border-bottom: unset;
    @media all and (max-width: $m - 1) {
        height: 100%;
        overflow-y: auto;
    }
    .el-sub-menu {
        $block-name: &;
        &__title {
            height: 60px;
            font-size: 16px;
            font-weight: 600;
            line-height: 60px;
        }
        &__icon-arrow {
            @media all and (min-width: $m) {
                display: none;
            }
        }
    }
    &.el-menu--horizontal {
        @media all and (max-width: $m - 1) {
            flex-wrap: wrap;
        }
    }
    &.el-menu--vertical {
        .el-menu-item-group {
            &__title {
                padding: 10px 0 5px 10px;
                font-size: 16px;
                font-weight: 600;
                color: $graphite;
            }
        }
    }
}
