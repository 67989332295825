.el-popper {
    &.header-popper-submenu {
        .el-menu {
            .el-menu-item {
                font-size: 15px;
                &:hover {
                    background-color: transparent;
                }
            }
            .el-menu-item-group {
                &__title {
                    padding: 10px 0 5px 10px;
                    font-size: 16px;
                    font-weight: 600;
                    color: $graphite;
                }
            }
            &--two-columns {
                .el-menu {
                    column-count: 2;
                }
            }
        }
    }
    &--capitalize {
        .el-select-dropdown {
            &__item {
                text-transform: capitalize;
            }
        }
    }
    &--stores {
        width: 560px;
        .el-select-dropdown {
            width: 550px !important;
            &__item {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                &-name {
                    font-size: 13px;
                }
                &-address {
                    font-size: 13px;
                    color: $gray;
                }
            }
        }
    }
}
