.el-card {
    $block-name: &;
    &__title {
        font-size: 18px;
        font-weight: 600;
    }
    &__text {
        &:not(:last-child) {
            margin-bottom: 5px;
        }
        &--bold {
            font-weight: 600;
        }
    }
}
