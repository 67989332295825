.not-found {
    $block-name: &;
    padding: 50px 0;
    &__title,
    &__text {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;
        font-weight: 600;
        text-align: center;
    }
    &__title {
        margin-bottom: 10px;
        font-size: 26px;
    }
    &__text {
        margin-bottom: 20px;
        font-size: 18px;
    }
    &__btn {
        max-width: 400px;
        margin: 0 auto;
    }
}
