.learning-module {
    $block-name: &;
    &__table {
        min-height: 50px;
        &-row {
            margin: 0 !important;
            border: solid darken($white-second, 5%);
            padding: 10px 0;
            &:first-child {
                border-width: 1px;
            }
            &:not(:first-child) {
                border-width: 0 1px 1px 1px;
            }
        }
        &-col {
            &--handle {
                cursor: pointer;
            }
            &--icon {
                .el-avatar {
                    vertical-align: middle;
                }
            }
            &--title {
                font-weight: 600;
            }
            &--description {
                margin: 10px 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @media all and (min-width: $s) {
                    margin: 0;
                }
            }
            &--buttons {
                @media all and (min-width: $s) {
                    text-align: right;
                }
                button {
                    margin: 5px 0;
                }
            }
        }
    }
}
